<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        "
        @click="closeModals"
      >
        ✖
      </div>
      <img
        v-if="success"
        src="@/assets/images/success-icon.png"
        alt="success-icon"
        class="mx-auto h-28"
      />
      <img
        v-else
        src="@/assets/images/error-icon.png"
        alt="error-icon"
        class="mx-auto h-28"
      />
    </div>
    <div slot="actionText" class="mt-8 mb-4">
      <div v-if="success">
        <h3 class="text-xl font-proximaMedium">
          Primary Ownership was transferred!
        </h3>
        <p class="text-sm" v-if="transferownership">
          {{ transferownership.data.receivingUserEmailAddress }} is now the Primary Owner of {{ transferownership.data.petCallName }}.
        </p>
        <p class="text-sm" v-else></p>
      </div>
      <div v-else>
        <h3 class="text-xl font-proximaMedium">
          Primary Ownership transfer declined!
        </h3>
        <p class="text-sm">Please contact the intended new primary owner.</p>
      </div>
    </div>
    <div slot="leftBtn"></div>
    <div slot="rightBtn"></div>
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    ModalsConfirm,
  },
  data() {
    return {
      success: true,
    };
  },
  computed: {
    ...mapGetters({
      transferownership: "transferownership/getConfirmTransferOwnership",
    }),
    transferModalsComp() {
      return this.$store.state.notifOwnershipStatusModalsState;
    },
  },
  methods: {
    closeModals() {
      this.$store.commit("notifOwnershipStatusModals", false);
    },
  },
};
</script>

<style>
</style>