<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        "
        @click="closeModals"
      >
        ✖
      </div>
    </div>
    <img
      slot="actionQuestion"
      src="@/assets/images/addowner-icon.png"
      alt="addowner-icon"
      class="m-auto"
    />
    <h1 slot="actionQuestion" class="mt-4 text-2xl">Transfer Rights</h1>
    <div
      slot="actionText"
      class="pb-6 flex flex-col justify-center items-center"
    >
      <SubmitButton
        :title="$t('transfer_rights_full_ownership')"
        background="bg-red"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openFullRightModals"
      />
      <SubmitButton
        :title="$t('transfer_rights_primary_ownership')"
        background="bg-orange"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openPrimaryRightModals"
      />
      <SubmitButton
        :title="$t('transfer_rights_co_ownership')"
        background="bg-primary"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openInviteCoownerModals"
      />
    </div>
    <div slot="leftBtn" class="hidden"></div>
    <div slot="rightBtn" class="hidden"></div>
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {};
  },
  computed: {
    transferModalsComp() {
      return this.$store.state.transferModalsState;
    },
  },
  methods: {
    openFullRightModals() {
      this.$store.commit("fullyRightModals", true);
      this.$store.commit("transferModals", false);
      this.$emit("whichRights", 1);
    },
    openPrimaryRightModals() {
      this.$store.commit("primaryRightModals", true);
      this.$store.commit("transferModals", false);
      this.$emit("whichRights", 2);
    },
    openInviteCoownerModals() {
      this.$store.commit("inviteCoownerModals", true);
      this.$store.commit("transferModals", false);
      this.$emit("whichRights", 3);
    },
    closeModals() {
      this.$store.commit("transferModals", false);
    },
  },
};
</script>

<style>
</style>