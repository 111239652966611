<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        "
        @click="closeModals"
      >
        ✖
      </div>
    </div>
    <img
      slot="actionQuestion"
      src="@/assets/images/identity-icon.png"
      alt="identity-icon"
      class="m-auto"
    />
    <h1 slot="actionQuestion" class="mt-4 text-2xl">Confirm Your Identity!</h1>
    <div
      slot="actionText"
      class="pb-6 flex flex-col justify-center items-center"
    >
      <SubmitButton
        :title="$t('confirm_id_otp_via_sms')"
        background="bg-primary"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openOtpModals"
      />
      <SubmitButton
        :title="$t('confirm_id_touch_id')"
        background="bg-primary"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openTouchidModals"
      />
      <SubmitButton
        :title="$t('confirm_id_face_id')"
        background="bg-primary"
        paddingX="px-8"
        width="w-10/12 md:w-5/12"
        class="mt-2"
        @click.native="openFaceidModals"
      />
    </div>
    <div slot="leftBtn" class="hidden"></div>
    <div slot="rightBtn" class="hidden"></div>
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    transferModalsComp() {
      return this.$store.state.confirmIdModalsState;
    },
  },
  methods: {
    ...mapActions({
      sendConfirmIdentityOtp: "transferownership/sendConfirmIdentityOtp",
    }),
    openOtpModals() {
      const data = {
        email_address: this.user.emailAddress,
        phone_number: this.user.phoneNo,
        lang: "EN",
        type: 3
      }
      this.sendConfirmIdentityOtp(data);
    },
    openTouchidModals() {
      this.$store.commit("touchidModals", true);
      this.$store.commit("confirmIdModals", false);
    },
    openFaceidModals() {
      this.$store.commit("faceidModals", true);
      this.$store.commit("confirmIdModals", false);
    },
    closeModals() {
      this.$store.commit("confirmIdModals", false);
    },
  },
};
</script>

<style>
</style>