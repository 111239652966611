<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion">
      <img
        class="mx-auto"
        src="@/assets/images/phone-icon-cl.png"
        alt="phone-icon-cl"
      />
    </div>
    <div slot="actionText" class="mt-6">
      <h3 class="text-xl font-proximaMedium">
        Confirm identity to transfer ownership.
      </h3>
      <p class="mt-2 text-sm">
        {{$t('phone_otp_by_sms_info_text')}}
        <span class="text-primary font-proximaMedium">{{ user.phoneNo }}</span>
      </p>
      <div>
        <span v-if="transferownership">{{ transferownership }}</span>
        <span v-else></span>
      </div>
      <div class="mt-4 mb-2 flex justify-center items-center">
        <InputPin @blurred="inputPinVal" class="input-otp" />
        <div class="flex items-end cursor-pointer">
          <img src="@/assets/images/refresh-icon.png" alt="refresh-icon" />
          <span class="ml-1">Resend code</span>
        </div>
      </div>
    </div>
    <BorderedButton
      slot="leftBtn"
      class="md:mr-1"
      :title="$t('global_cancel')"
      borderColor="border-mediumGrey"
      textCl="text-darkGrey"
      paddingX="px-8"
      width="w-full"
      @click.native="closeModals"
    />
    <SubmitButton
      slot="rightBtn"
      class="md:ml-1"
      :title="$t('global_confirm')"
      background="bg-success"
      paddingX="px-8"
      width="w-full"
      @click.native="openConfirmIdModals"
    />
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import InputPin from "../forms/InputPin.vue";
import FormError from "../warning/FormError.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    InputPin,
    FormError,
  },
  props: ["user"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      transferownership: "transferownership/getOtp",
    }),
    transferModalsComp() {
      return this.$store.state.confirmOtpModalsState;
    },
  },
  methods: {
    inputPinVal(val) {
      this.$emit("getOtpNumber", val);
    },
    openConfirmIdModals() {
      this.$parent.addNewOwner();
    },
    closeModals() {
      this.$store.commit("confirmOtpModals", false);
    },
  },
};
</script>

<style>
.input-otp {
  margin: 0 5px 0 0;
}
input#password-input {
  width: max-content !important;
  letter-spacing: 13.6px !important;
}
</style>