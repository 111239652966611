<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        "
        @click="closeModals"
      >
        ✖
      </div>
      <h3 class="text-xl">Touch ID Verification</h3>
    </div>
    <div slot="actionText" class="flex flex-col justify-center items-center">
      <img
        class="h-24 w-24 mt-6"
        src="@/assets/images/faceid-icon.png"
        alt="faceid-icon"
      />
      <SubmitButton
        :title="$t('facied_scan_face')"
        background="bg-primary"
        paddingX="px-12"
        width="w-max"
      />
    </div>
    <div slot="leftBtn"></div>
    <div slot="rightBtn"></div>
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
  },
  data() {
    return {};
  },
  computed: {
    transferModalsComp() {
      return this.$store.state.faceidModalsState;
    },
  },
  methods: {
    openConfirmIdModals() {
      this.$store.commit("confirmIdModals", true);
      this.$store.commit("fullyRightModals", false);
    },
    closeModals() {
      this.$store.commit("faceidModals", false);
    },
  },
};
</script>

<style>
</style>