<template>
  <ModalsConfirm :show="transferModalsComp">
    <h3 slot="actionQuestion" class="text-xl">Transfer Primary Rights</h3>
    <p slot="actionText" class="text-sm">
      <span class="text-red">{{ $t("global_attention") }}</span> you'll become a
      co-owner and lose rights to managing pet status, ownership, and reversing
      changes made to pet record!
    </p>
    <BorderedButton
      slot="leftBtn"
      class="md:mr-1"
      :title="$t('global_cancel')"
      borderColor="border-mediumGrey"
      textCl="text-darkGrey"
      paddingX="px-8"
      width="w-full"
      @click.native="closeModals"
    />
    <SubmitButton
      slot="rightBtn"
      class="md:ml-1"
      :title="$t('global_confirm')"
      background="bg-error"
      paddingX="px-8"
      width="w-full"
      @click.native="openConfirmIdModals"
    />
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    transferModalsComp() {
      return this.$store.state.primaryRightModalsState;
    },
  },
  methods: {
    ...mapActions({
      sendConfirmIdentityOtp: "transferownership/sendConfirmIdentityOtp",
    }),
    openConfirmIdModals() {
      const data = {
        email_address: this.user.emailAddress,
        phone_number: this.user.phoneNo,
        lang: "EN",
        type: 3
      }
      this.sendConfirmIdentityOtp(data);
    },
    closeModals() {
      this.$store.commit("primaryRightModals", false);
    },
  },
};
</script>

<style>
</style>