<template>
  <ModalsConfirm :show="transferModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        "
        @click="closeModals"
      >
        ✖
      </div>
      <h3 class="text-xl">Touch ID Verification</h3>
    </div>
    <div slot="actionText" class="flex flex-col justify-center items-center">
      <img class="h-24 w-24 my-6" src="@/assets/images/touchid-icon.png" alt="touchid-icon" />
      <h5>Please scan your finger to confirm your identity.</h5>
    </div>
    <div slot="leftBtn"></div>
    <div slot="rightBtn"></div>
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";

export default {
  components: {
    ModalsConfirm,
  },
  data() {
    return {};
  },
  computed: {
    transferModalsComp() {
      return this.$store.state.touchidModalsState;
    },
  },
  methods: {
    openConfirmIdModals() {
      this.$store.commit("confirmIdModals", true);
      this.$store.commit("fullyRightModals", false);
    },
    closeModals() {
      this.$store.commit("touchidModals", false);
    },
  },
};
</script>

<style>
</style>