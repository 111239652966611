<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col lg:flex-row items-start">
    <SectionLeft class="hidden lg:block" />
    <div class="h-full lg:h-screen w-full lg:w-6/12 px-4 lg:pr-32">
        <OwnershipSection />
    </div>
    <img
        class="h-28 mt-8 md:mt-0 mb-4 md:mb-8 lg:hidden m-auto"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import OwnershipSection from "../components/transferownership/OwnershipSection.vue";

export default {
  components: {
    SectionLeft,
    OwnershipSection
  },
};
</script>