<template>
  <div class="my-6 flex items-center">
    <div class="w-9/12 md:w-8/12 pl-6 relative">
      <img class="rounded-full absolute left-0" height="52px" width="52px" src="@/assets/images/profilepic-icon.png" alt="profilepic-icon" />
      <div class="
          w-full
          rounded-r-lg
          bg-white
          py-2
          px-4
          flex
          items-center
          justify-between
        ">
        <span v-if="coowner.ownershipType" class="
            w-9/12
            pl-5
            text-left
            font-proximaLight
            text-ellipsis
            overflow-hidden
          ">{{ coowner.userEmailAddress }}</span>
        <span v-else class="
            w-full
            pl-5
            pt-1
            pb-2
            text-left
            font-proximaLight
            text-ellipsis
            overflow-hidden
          ">{{ coowner.receivingUserEmailAddress }}</span>
        <div v-if="coowner.ownershipType" class="w-3/12 flex items-center justify-evenly">
          <TrashIcon class="cursor-pointer" width="36" height="36" iconColor="#0AC0CB" bgColor="transparent" @click.native="deleteCoownerModals = !deleteCoownerModals" />
          <CrownIcon class="cursor-pointer" :iconColor="hoverComp" @mouseover.native="hover = true" @mouseleave.native="hover = false" @click.native="transferRightModals = !transferRightModals" />
        </div>
      </div>
    </div>
    <div class="w-3/12 md:w-4/12 ml-4">
      <div v-if="!coowner.ownershipType" class="
          w-max
          md:h-full
          px-3
          py-1
          md:py-auto md:px-6
          font-proximaLight
          text-orange
          border border-orange
          rounded
          flex
          items-center
        ">
        Pending
      </div>
    </div>

    <!-- delete coowner end -->
    <ModalsConfirm :show="deleteCoownerModals" @close="deleteCoownerModals = false">
      <h3 slot="actionQuestion" class="text-xl">Remove {{ coowner.userEmailAddress }} ?</h3>
      <p slot="actionText" class="text-sm">
        <span class="text-red">{{ $t('global_attention') }}</span> you'll remove co-owner of your pet.
      </p>
      <BorderedButton slot="leftBtn" class="md:mr-1" :title="$t('global_cancel')" borderColor="border-mediumGrey" textCl="text-darkGrey" paddingX="px-8" width="w-full" @click.native="deleteCoownerModals = false" />
      <SubmitButton slot="rightBtn" class="md:ml-1" :title="$t('global_confirm')" background="bg-error" paddingX="px-8" width="w-full" @click.native="confirmDeleteCoowner" />
    </ModalsConfirm>
    <!-- delete coowner modals end -->

    <!-- primary right modals -->
    <ModalsConfirm :show="transferRightModals" @close="transferRightModals = false">
      <h3 slot="actionQuestion" class="text-xl">Transfer Primary Rights</h3>
      <p slot="actionText" class="text-sm">
        <span class="text-red">{{ $t('global_attention') }}</span> you'll become a co-owner and
        lose rights to managing pet status, ownership, and reversing changes
        made to pet record!
      </p>
      <BorderedButton slot="leftBtn" class="md:mr-1" :title="$t('global_cancel')" borderColor="border-mediumGrey" textCl="text-darkGrey" paddingX="px-8" width="w-full" @click.native="transferRightModals = false" />
      <SubmitButton slot="rightBtn" class="md:ml-1" :title="$t('global_confirm')" background="bg-error" paddingX="px-8" width="w-full" @click.native="openOtpModals" />
    </ModalsConfirm>
    <!-- primary right modals end -->
  </div>
</template>

<script>
import CrownIcon from "../icons/CrownIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { mapActions } from "vuex";

export default {
  props: ["coowner", "user"],
  components: {
    CrownIcon,
    TrashIcon,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      hover: false,
      deleteCoownerModals: false,
      transferRightModals: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#9ca3af";
      } else {
        return "#E5E5E5";
      }
    },
  },
  methods: {
    ...mapActions({
      deleteCoowner: "transferownership/deleteCoowner",
      sendConfirmIdentityOtp: "transferownership/sendConfirmIdentityOtp"
    }),
    confirmDeleteCoowner() {
      this.deleteCoowner(this.coowner)
      this.deleteCoownerModals = false
    },
    openConfirmIdModals() {
      this.$store.commit("confirmIdModals", true);
      this.transferRightModals = false;
    },
    openOtpModals() {
      this.transferRightModals = false;
      const data = {
        email_address: this.coowner.userEmailAddress,
        phone_number: this.user.phoneNo,
        lang: "EN",
        type: 4
      }
      this.sendConfirmIdentityOtp(data);
    },
  },
};
</script>

<style>

</style>