<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.685 14H3.3142C2.83471 14.0013 2.37111 13.8308 2.00991 13.5202C1.64872 13.2096 1.4146 12.7802 1.35125 12.3122L0.0109821 2.5708C-0.0222977 2.33163 0.020492 2.08818 0.133465 1.87394C0.246438 1.65969 0.424043 1.48518 0.641851 1.37442C0.859658 1.26365 1.10697 1.22206 1.34976 1.25537C1.59255 1.28869 1.81889 1.39527 1.99766 1.56045L4.20969 3.57531C4.32661 3.6866 4.46552 3.77303 4.61779 3.82923C4.77007 3.88542 4.93245 3.91018 5.09485 3.90197C5.25725 3.89375 5.41619 3.85274 5.56182 3.78147C5.70744 3.71021 5.83663 3.61022 5.94136 3.48771L8.5863 0.415784C8.69764 0.28555 8.83653 0.180857 8.99327 0.109035C9.15 0.0372136 9.32078 0 9.49365 0C9.66652 0 9.8373 0.0372136 9.99403 0.109035C10.1508 0.180857 10.2897 0.28555 10.401 0.415784L13.0578 3.48771C13.1616 3.60832 13.2891 3.70694 13.4327 3.77759C13.5763 3.84824 13.7329 3.88946 13.8932 3.89874C14.0534 3.90802 14.2139 3.88518 14.3649 3.83159C14.5159 3.778 14.6543 3.69479 14.7717 3.58699L16.9837 1.57214C17.1607 1.40128 17.3876 1.28933 17.6326 1.25202C17.8775 1.2147 18.1282 1.25389 18.3494 1.36408C18.5705 1.47426 18.7511 1.64992 18.8657 1.86636C18.9802 2.0828 19.0231 2.32915 18.9882 2.5708L17.6479 12.3063C17.5859 12.7755 17.3523 13.2062 16.991 13.518C16.6297 13.8297 16.1653 14.0011 15.685 14Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 19,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "#E5E5E5",
    },
  },
};
</script>