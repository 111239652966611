<template>
  <div class="flex flex-col justify-center items-center">
    <div
      class="w-full mt-10 hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal
      :titlemenu1="$t('account_dropdown_menu_pet_records')"
      :to1="'/petoverview/new'"
      :titlemenu2="'Dog Record: ' + petDetails.callName"
      titlemenu3="Transfer Ownership"
    />
    <div
      v-if="coownersData"
      class="md:my-14 w-full md:w-10/12 lg:w-full font-proximaMedium"
    >
      <img
        class="m-auto rounded-full w-24 h-24 object-cover"
        src="@/assets/images/peticons/dog_icon.png"
        alt="dog_icon"
      />
      <div class="mt-4 flex justify-start items-center">
        <h3 class="text-2xl p-0 m-0">Co-owner</h3>
        <span class="ml-2 pt-2 text-xs font-proximaLight"
          >({{ coownersData.coowners.length }} of 9)</span
        >
      </div>
      <div class="h-96 overflow-y-auto">
        <ul v-if="coownersData.coowners" class="mt-6">
          <li v-for="(coowner, i) in coownersData.coowners" :key="i">
            <OwnershipEach :coowner="coowner" :user="user" />
          </li>
        </ul>
        <ul v-else></ul>
        <ul v-if="coownersData.pending_ownership">
          <li v-for="(coowner, i) in coownersData.pending_ownership" :key="i">
            <OwnershipEach :coowner="coowner" :user="user" />
          </li>
        </ul>
        <ul v-else></ul>
      </div>
      <div class="mt-10 lg:w-9/12 flex justify-center">
        <img
          class="cursor-pointer"
          src="@/assets/images/transferownership-icon.png"
          alt="transferownership-icon"
          @click="addOwnerModals = !addOwnerModals"
        />
      </div>
    </div>
    <div v-else class="mt-10 text-center font-proximaMedium">
      No Data. <br />
      Go back to Pet Records and select the pet.
    </div>

    <ModalsConfirm :show="addOwnerModals" @close="addOwnerModals = false">
      <img
        slot="actionQuestion"
        src="@/assets/images/addowner-icon.png"
        alt="addowner-icon"
        class="m-auto"
      />
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        {{ $t("ownership_section_add_new_owner") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <SubmitButton
          :title="$t('ownership_section')"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-6/12"
        />
        <span class="my-2">{{ $t("global_or") }}</span>
        <input
          type="text"
          class="w-10/12 md:w-6/12 border-2 py-3 px-4 border-primary rounded-md"
          :placeholder="$t('ownership_section_enter_email_phone_number')"
          v-model="receivingUserEmailAddress"
        />
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeAddOwnerModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="transferModalsFunc(true)"
      />
    </ModalsConfirm>

    <TransferRightsModals v-on:whichRights="whichRights" />
    <TransferPrimaryRightsModals />
    <TransferFullRightsModals />
    <InviteCoownerModals />
    <ConfirmIdModals />
    <TouchidModals />
    <FaceidModals />
    <ConfirmOtpModals :user="user" @getOtpNumber="getOtpNumber" />
    <TransferredModals />
    <NotifModals />
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import OwnershipEach from "./OwnershipEach.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import TransferRightsModals from "./TransferRightsModals.vue";
import TransferPrimaryRightsModals from "./TransferPrimaryRightsModals.vue";
import TransferFullRightsModals from "./TransferFullRightsModals.vue";
import InviteCoownerModals from "./InviteCoownerModals.vue";
import ConfirmIdModals from "./ConfirmIdModals.vue";
import TouchidModals from "./TouchidModals.vue";
import FaceidModals from "./FaceidModals.vue";
import ConfirmOtpModals from "./ConfirmOtpModals.vue";
import TransferredModals from "./TransferredModals.vue";
import NotifModals from "./NotifModals.vue";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    OwnershipEach,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    TransferRightsModals,
    TransferPrimaryRightsModals,
    TransferFullRightsModals,
    InviteCoownerModals,
    ConfirmIdModals,
    TouchidModals,
    FaceidModals,
    ConfirmOtpModals,
    TransferredModals,
    NotifModals,
  },
  data() {
    return {
      addOwnerModals: false,
      transferModals: false,
      coOwners: [
        { id: 1, name: "Co Owner 1" },
        { id: 2, name: "Co Owner 2" },
        { id: 3, name: "Co Owner 3" },
        { id: 4, name: "Co Owner 4" },
      ],
      petDetails: {},
      receivingUserEmailAddress: "",
      receivingUserPhoneNumber: "",
      otp: "",
      transfer_rights: "",
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    this.petDetails = JSON.parse(retrievedObject);

    this.updateCoowner();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      coownersData: "transferownership/getCoowners",
    }),
  },
  methods: {
    ...mapActions({
      confirmTransferOwnership: "transferownership/confirmTransferOwnership",
      getCoowners: "transferownership/getCoowners",
    }),
    async updateCoowner() {
      this.getCoowners(this.petDetails.petID);
    },
    getOtpNumber(i) {
      this.otp = i;
    },
    async addNewOwner() {
      const formData = new FormData();
      formData.append("petID", this.petDetails.petID);
      formData.append("transferringUserEmailAddress", this.user.emailAddress);
      formData.append(
        "receivingUserPhoneNumber",
        this.receivingUserPhoneNumber
      );
      formData.append(
        "receivingUserEmailAddress",
        this.receivingUserEmailAddress
      );
      formData.append("otp", this.otp);
      formData.append("transfer_rights", this.transfer_rights);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      this.confirmTransferOwnership(formData);
    },
    transferModalsFunc(i) {
      this.addOwnerModals = false;
      this.$store.commit("transferModals", i);
    },
    closeAddOwnerModals() {
      this.addOwnerModals = false;
      this.receivingUserEmailAddress = "";
    },
    whichRights(data) {
      this.transfer_rights = data;
    },
  },
};
</script>

<style>
</style>